import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { CaseState } from '@debbie/common/dist/cases'
import * as reportsAPI from '@debbie/cortex/dist/api/insights'
import { RootState } from '@debbie/cortex/dist/reducer'
import { Button, Modal, Select, Panel } from '@debbie/iris/dist/components'
import { Row, Col } from '@debbie/iris/dist/components/Grid'
import EmailLarge from '@debbie/iris/dist/components/Icons/EmailLarge'
import { useAllCreditors } from '@debbie/cortex/dist/creditors'
import { h } from '@debbie/cortex/dist'

const DefaultReporter = () => {
  const creditorsLoadable = useAllCreditors()
  const creditors = h.hasData(creditorsLoadable) ? creditorsLoadable.data : null
  const lang = useSelector((s: RootState) => s.lang)
  const currentLang = useSelector((s: RootState) => s.currentLang)

  const [billingCaseOverviewOpen, setBillingCaseOverviewOpen] = useState(false)
  const [creditorSelect, setCreditorSelect] = useState(null)
  const [exportStarted, setExportStarted] = useState(false)
  const [loading, setLoading] = useState(false)

  const setCreditor = (e) => {
    if (e.target.value === lang.insights.selectCreditor) {
      setCreditorSelect(null)
      return
    }

    setCreditorSelect(e.target.value)
  }

  const toggleCaseOverviewModal = () => {
    setBillingCaseOverviewOpen(!billingCaseOverviewOpen)
    setExportStarted(false)
  }

  return (
    <Row>
      <Col width="3">
        <PanelStyled header="Sagsoverblik">
          <p>
            Oversigt over sager inkl. finansielt overblik, status og debitor
            information.
          </p>
          <Button
            size="small"
            theme="secondary"
            onClick={toggleCaseOverviewModal}
            block
          >
            {lang.insights.orderReport}
          </Button>
          {billingCaseOverviewOpen && (
            <Modal
              onToggle={toggleCaseOverviewModal}
              header="Sagsoverblik"
              maxWidth="282px"
            >
              {exportStarted ? (
                <div>
                  <EmailLarge
                    style={{
                      width: '8em',
                      height: '8em',
                      margin: '0 auto',
                      display: 'block',
                    }}
                  />
                  <h3 style={{ textAlign: 'center' }}>
                    {lang.insights.reportOrdered}
                  </h3>
                  <p style={{ textAlign: 'center' }}>
                    {lang.insights.reportOrderedText}
                  </p>
                  <Button block onClick={toggleCaseOverviewModal}>
                    {lang.insights.reportOrderedCloseButton}
                  </Button>
                </div>
              ) : (
                <div>
                  <Select onChange={setCreditor} value={creditorSelect}>
                    <option>{lang.insights.selectCreditor}</option>
                    {creditors.map((creditor) => (
                      <option
                        key={creditor.creditorId}
                        value={creditor.creditorId}
                      >
                        {creditor.name}
                      </option>
                    ))}
                  </Select>
                  <Button
                    block
                    loading={loading}
                    size="small"
                    disabled={!creditorSelect}
                    onClick={async () => {
                      setLoading(true)

                      try {
                        await reportsAPI.requestCaseOverviewReport(
                          [creditorSelect],
                          currentLang,
                          CaseState.ALL,
                        )

                        setExportStarted(true)
                      } catch (error) {
                        console.error(error)
                        alert('Error')
                      }

                      setLoading(false)
                      setCreditorSelect(null)
                    }}
                  >
                    {lang.insights.orderReport}
                  </Button>
                </div>
              )}
            </Modal>
          )}
        </PanelStyled>
      </Col>
    </Row>
  )
}

export default DefaultReporter

const PanelStyled = styled(Panel)`
  .body > p {
    min-height: 60px;
    margin-top: 8px;
  }
`
