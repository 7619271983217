import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { CreditorState } from '@debbie/common/dist/creditor'
import { RootState } from '@debbie/cortex/dist/reducer'
import { CaseCreator } from '@debbie/iris/dist/components'
import { CaseCreationStep } from '@debbie/iris/dist/components/CaseCreator/CaseCreator'
import { useAllCreditors } from '@debbie/cortex/dist/creditors'
import { h } from '@debbie/cortex/dist'

type Props = {}

const genLink = (caseId: string | null, step: CaseCreationStep) => {
  return caseId != null ? `/create-case/${caseId}/${step}` : null
}

const CreateCase = (props: Props) => {
  const { caseId, slug }: { caseId: string; slug: CaseCreationStep } =
    useParams()

  const allCreditorsLoadable = useAllCreditors()
  const allCreditors = h.hasData(allCreditorsLoadable)
    ? allCreditorsLoadable.data
    : null
  const selectedCreditor = useSelector((s: RootState) => s.selectedCreditor)

  const creditor = allCreditors
    ? allCreditors.find((creditor) => creditor.creditorId === selectedCreditor)
    : null

  if (creditor?.state === CreditorState.Ceased) {
    return null
  }

  if (typeof caseId !== 'string') {
    return <CaseCreator genLink={genLink} />
  }

  return <CaseCreator genLink={genLink} state={{ type: slug, caseId }} />
}

export default CreateCase
