import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Entity } from '@debbie/common/dist'
import { getEmbedAreas, EmbedPlacement } from '@debbie/common/dist/embeds'
import { InteractionType } from '@debbie/common/dist/interactions'
import { Direction } from '@debbie/common/dist/interactions'
import { c, h } from '@debbie/cortex/dist'
import * as updatesLib from '@debbie/cortex/dist/api/updates'
import { selectCaseUpdates } from '@debbie/cortex/dist/case/selectors'
import { selectCaseById } from '@debbie/cortex/dist/cases/selectors'
import { selectCustomerById } from '@debbie/cortex/dist/customers/selectors'
import { selectRole } from '@debbie/cortex/dist/me/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import { selectTenant } from '@debbie/cortex/dist/tenant/selectors'
import { selectUpdateTypesMap } from '@debbie/cortex/dist/update-types/selectors'
import {
  Panel,
  CopyToClipboard,
  CaseStatus,
  PageMenu,
  MenuItem,
  PlaceholderLoader,
  CaseInfo,
  ButtonDrop,
  EmbedsFrame,
  Properties,
} from '@debbie/iris/dist/components'
import CasePauseStatus, {
  getActivePauses,
} from '@debbie/iris/dist/components/CasePauseStatus'
import CaseVouchers from '@debbie/iris/dist/components/economy/case-vouchers/CaseVouchers'
import { DecoratedVoucher } from '@debbie/iris/dist/components/economy/case-vouchers/types'
import CreateVoucher from '@debbie/iris/dist/components/economy/CreateVoucher'
import { Col, Row } from '@debbie/iris/dist/components/Grid'
import CaseHistory from '@debbie/iris/dist/components/Hist/CaseHistory'
import { NoteInteraction } from '@debbie/iris/dist/components/interactions'
import WorkflowStatus from '@debbie/iris/dist/components/WorkflowStatus'
import { VoucherTypeIds } from '@debbie/common/dist/economy/vouchers'
import { useCaseVouchersByCase } from '@debbie/cortex/dist/case-vouchers'

const CaseSummary = (props: { id: string }) => {
  const { id } = props

  const singleCase = useSelector((s: RootState) => selectCaseById(s, id))
  const customer = useSelector(
    (s: RootState) =>
      singleCase && selectCustomerById(s, singleCase.customerId),
  )
  const caseUpdates = useSelector(
    (s: RootState) => singleCase && selectCaseUpdates(s, singleCase.caseId),
  )
  const updateTypesMap = useSelector(selectUpdateTypesMap)
  const caseVouchers_ = useCaseVouchersByCase(singleCase?.caseId)
  const _caseVouchers = h.hasData(caseVouchers_) ? caseVouchers_.data : null
  const meRole = useSelector(selectRole)
  const tenant = useSelector(selectTenant)
  const lang = useSelector((s: RootState) => s.lang)
  const [addVoucherOpen, setAddVoucherOpen] = useState(false)

  useEffect(() => {
    if (singleCase) {
      c.newInteractions.createNewInteraction(singleCase.caseId)
    }
  }, [singleCase])

  const continueCase = async () => {
    const awaitingClientPauses = getActivePauses(
      caseUpdates,
      updateTypesMap,
      meRole,
    ).filter((update) => update.isAwaitingClient)

    if (awaitingClientPauses) {
      const promises = awaitingClientPauses.map((pause) =>
        updatesLib.patch(pause.update.updateId),
      )
      await Promise.all(promises)
      c.cases.getUpdates(singleCase.caseId)
    }
  }

  const embedAreas = tenant
    ? getEmbedAreas(tenant.embeds, EmbedPlacement.CreditorWebCasePage)
    : []

  const caseVouchers =
    _caseVouchers &&
    _caseVouchers.map((caseVoucher): DecoratedVoucher => {
      return { ...caseVoucher, children: [] }
    })

  return (
    <Row>
      <Col size="md" width="12">
        <PageMenu
          breadCrumb={[
            {
              text: lang.navigation.cases,
              path: '/cases',
            },
            {
              text: `#${singleCase?.id}`,
              path: `/cases/${singleCase?.id}`,
              copyToClipBoard: singleCase?.id,
            },
          ]}
          contentLeft={
            <>
              {singleCase && singleCase.workflowId && customer && (
                <MenuItem>
                  <WorkflowStatus case={singleCase} customer={customer} />
                </MenuItem>
              )}
            </>
          }
          contentRight={
            singleCase && (
              <>
                <MenuItem>
                  <CaseStatus
                    status={singleCase.status}
                    statusTime={singleCase.statusTime}
                    endReasonId={singleCase.endReasonId}
                  />
                </MenuItem>
              </>
            )
          }
        />
      </Col>
      <Col size="md" width="12">
        {singleCase && <CasePauseStatus case={singleCase} />}
      </Col>
      <Col size="md" width="5">
        <Panel
          header={
            <>
              <CopyToClipboard text={singleCase && singleCase.caseId} hideIcon>
                {lang.cases.caseInfo}
              </CopyToClipboard>
            </>
          }
          bodyStyle={{
            minHeight: '300px',
          }}
        >
          <CaseInfo caseId={id} />
        </Panel>
        <Panel
          header={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {lang.economy.caseEconomy}
              <ButtonDrop
                open={addVoucherOpen}
                onOpen={() => {
                  setAddVoucherOpen(true)
                }}
                onClose={() => {
                  setAddVoucherOpen(false)
                }}
                size="mini"
              >
                {singleCase && (
                  <CreateVoucher
                    caseId={singleCase.caseId}
                    creditorId={singleCase.creditorId}
                    voucherTypeFilter={[VoucherTypeIds.PRINCIPAL]}
                    onCreate={() => {
                      setAddVoucherOpen(false)
                    }}
                    caseVouchers={caseVouchers}
                    singleCase={singleCase}
                  />
                )}
              </ButtonDrop>
            </div>
          }
        >
          {singleCase && caseVouchers ? (
            <CaseVouchers singleCase={singleCase} caseVouchers={caseVouchers} />
          ) : (
            <PlaceholderLoader />
          )}
        </Panel>
        {embedAreas &&
          singleCase &&
          embedAreas.map((embedArea) => (
            <Panel
              key={embedArea.embedId}
              header={embedArea.title}
              bodyStyle={{ overflow: 'hidden' }}
            >
              <div
                style={{
                  margin: '-20px -20px -25px -20px',
                }}
              >
                <EmbedsFrame
                  id={embedArea.embedId}
                  height={
                    embedArea.placement === EmbedPlacement.CreditorWebCasePage
                      ? embedArea.details.height
                      : undefined
                  }
                  context={{
                    caseId: singleCase.caseId,
                  }}
                />
              </div>
            </Panel>
          ))}
        {singleCase && singleCase.caseGroup && (
          <Panel header={lang.properties.properties}>
            <Properties
              propRef={Entity.Case}
              propRefId={singleCase.caseId}
              scope="case_groups"
              scopeId={singleCase.caseGroup.id}
              editable={false}
            />
          </Panel>
        )}
      </Col>
      <Col size="md" width="7">
        <Panel header={lang.cases.sendNoteToCollector}>
          {singleCase && (
            <NoteInteraction
              referenceId={singleCase.caseId}
              referenceType={Entity.Case}
              id={singleCase.id}
              options={{
                direction: Direction.INCOMING,
                type: InteractionType.ClientNote,
              }}
              sendButtonText={lang.cases.sendNoteToCollectorButton}
              continueCase={continueCase}
            />
          )}
        </Panel>
        {singleCase && <CaseHistory caseId={singleCase.caseId} />}
      </Col>
    </Row>
  )
}

export default CaseSummary
