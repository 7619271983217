import 'react-datepicker/dist/react-datepicker.css'

import * as Sentry from '@sentry/browser'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import { Provider } from '@debbie/cortex/dist'
import X from '@debbie/iris/dist/components/X'
import Authenticate from '@debbie/iris/dist/components/Authenticate'

import logo from './assets/images/logo.svg'
import { ENABLE_SENTRY, SENTRY_DSN } from './config'
import LoggedInView from './LoggedInView'
if (ENABLE_SENTRY && SENTRY_DSN) Sentry.init({ dsn: SENTRY_DSN })
import ErrorBoundary from '@debbie/iris/dist/components/ErrorBoundary'
import { ErrorPage } from '@debbie/iris/dist/components/ErrorPage'

moment.locale()

const Wrapper = styled.div``

const App = () => {
  return (
    // @ts-ignore
    <ErrorBoundary fallback={<ErrorPage />}>
      <Wrapper>
        <Provider>
          <X
            logo={logo}
            loggedInView={
              <Authenticate>
                <LoggedInView />
              </Authenticate>
            }
          />
        </Provider>
      </Wrapper>
    </ErrorBoundary>
  )
}

export default App
