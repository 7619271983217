import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { c, h } from '@debbie/cortex/dist'
import { selectMe } from '@debbie/cortex/dist/me/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import { selectTenantId } from '@debbie/cortex/dist/selectedOwners/selectors'
import { PageBase, Panel, TopNav } from '@debbie/iris/dist/components'
import Integrations from '@debbie/iris/dist/components/integrations/Integrations'
import { useCreditor } from '@debbie/cortex/dist/creditors'

type Props = {
  creditorId: string
}

const CreditorIntegrations = (props: Props) => {
  const creditorId = props.creditorId
  const me = useSelector(selectMe)
  const meLoader = useSelector((s: RootState) => s.me)
  const selectedTenant = useSelector(selectTenantId)

  const creditorActivations = useSelector(
    (state: RootState) => state.creditorIntegrationActivations[creditorId],
  )

  const integrationList = useSelector(
    (state: RootState) => state.integrationList,
  )

  const creditor = useCreditor(creditorId)

  const lang = useSelector((state: RootState) => state.lang)

  useEffect(() => {
    if (h.isNotLoaded(integrationList)) {
      c.integrations.getAll()
    }
    if (!h.hasData(creditorActivations)) {
      c.integrations.getCreditorActivations(creditorId)
    }

    if (!h.hasData(meLoader)) {
      c.me.get()
    }
  }, [creditorId, selectedTenant])

  const activations = creditorActivations

  if (!h.hasData(integrationList) || !h.hasData(activations)) {
    return null
  }
  const activeActivations = activations.data.filter(
    (integration) => integration.active,
  )

  // TODO: Possibly do this in backend
  const integrations = integrationList.data.filter((i) =>
    (me != null
      ? me.tenants
          .find((t) => t.id === selectedTenant)!
          .allowedIntegrations.includes(i.id)
      : false) && h.hasData(creditor)
      ? creditor.data.allowedIntegrations.includes(i.id)
      : false,
  )

  const installedIntegrations = integrations.filter(
    (integration) =>
      activeActivations.findIndex(
        (activation) => activation.integrationId === integration.id,
      ) !== -1,
  )

  const availableIntegrations = integrations.filter(
    (integration) =>
      activeActivations.findIndex(
        (activation) => activation.integrationId === integration.id,
      ) === -1,
  )

  return (
    <>
      <TopNav />
      <PageBase>
        <div
          style={{
            maxWidth: '1400px',
            margin: '0px auto',
          }}
        >
          <Panel header={lang.integrations.activeIntegrations}>
            <Integrations
              genLink={(i) => `/${props.creditorId}/integrations/${i.id}`}
              creditorId={creditorId}
              integrations={installedIntegrations}
            ></Integrations>
          </Panel>
          <Panel header={lang.integrations.available}>
            <Integrations
              genLink={(i) => `/${props.creditorId}/integrations/${i.id}`}
              creditorId={creditorId}
              integrations={availableIntegrations}
            ></Integrations>
          </Panel>
        </div>
      </PageBase>
    </>
  )
}

export default CreditorIntegrations
