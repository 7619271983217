import React from 'react'
import styled from 'styled-components'

import { Collector } from '@debbie/common/dist/collectors'

import { FILEBASE } from '../config'
import { Button } from '@debbie/iris/dist/components'
import { useSelector } from 'react-redux'
import { RootState } from '@debbie/cortex/dist/reducer'

type Props = {
  header: string
  error: string
  image?: string
  showIcon?: boolean
  collector: Collector
}

const Error = (props: React.PropsWithChildren<Props>) => {
  const lang = useSelector((s: RootState) => s.lang)

  const logout = () => {
    localStorage.clear()
    window.location.reload()
  }

  return (
    <Wrapper>
      {props.showIcon ? (
        <Logo
          src={
            props.collector && props.collector.logoFileId
              ? `${FILEBASE}/${props.collector.logoFileId}/logo`
              : null
          }
        ></Logo>
      ) : null}
      <ErrorContainer>
        {props.image != null ? <Image src={props.image}></Image> : null}
        <h1>{props.header}</h1>
        <p>{props.error}</p>
        <Button value={lang.auth.logout} onClick={logout} />
      </ErrorContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  position: fixed;
  margin: 25px;
  top: 0;
  left: 0;
  height: 33px;
`

const Image = styled.img`
  max-height: 50vh;
  max-width: 50vw;
`

const ErrorContainer = styled.div`
  text-align: center;
`

export default Error
